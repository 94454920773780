import * as React from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import Menu from "@mui/material/Menu";
import MenuIcon from "@mui/icons-material/Menu";
import Container from "@mui/material/Container";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import Tooltip from "@mui/material/Tooltip";
import MenuItem from "@mui/material/MenuItem";
import AdbIcon from "@mui/icons-material/Adb";
import logo from "../../images/main/aws-certified-solutions-architect-associate.png";
import styles from "./navBar.module.css";
import { Link } from "react-router-dom";

const pages = [
	{
		name: "Overview",
		href: "/",
	},
	{
		name: "Projects",
		href: "/projects",
	},
	{
		name: "Blog",
		href: "/articles",
	},
	{
		name: "Contact",
		href: "/contact",
	}
];

function ResponsiveAppBar({active= ""}) {
	const [anchorElNav, setAnchorElNav] = React.useState(null);
	
	const handleOpenNavMenu = (event) => {
		setAnchorElNav(event.currentTarget);
	};
	const handleCloseNavMenu = () => {
		setAnchorElNav(null);
	};

	return (
		<AppBar position="fixed" sx={{ backgroundColor: "primary.background" }}>
			<Container maxWidth="xl" sx={{ color: "black" }}>
				<Toolbar
					disableGutters
					sx={{ justifyContent: "space-between", display: "flex" }}
				>
					<Box display={"flex"} alignItems={"center"}>
						<img
							src={logo}
							href="/"
							width="40"
							height="40"
							alt="AWS Certified Solutions Architect Associate"
							className={styles.deskLogo}
						/>
						<Typography
							variant="h1"
							noWrap
							component="a"
							href="/"
							sx={{
								mr: 2,
								display: { xs: "none", md: "flex", ml: 1.2 },
								fontWeight: 700,
								letterSpacing: ".2rem",
								color: "inherit",
								textDecoration: "none",
							}}
						>
							Prajwal Anjanappa
						</Typography>
					</Box>

					<Box
						sx={{
							flexGrow: 1,
							display: {
								xs: "flex",
								md: "none",
								color: "inherit",
							},
						}}
					>
						<IconButton
							size="large"
							aria-label="account of current user"
							aria-controls="menu-appbar"
							aria-haspopup="true"
							onClick={handleOpenNavMenu}
							color="inherit"
						>
							<MenuIcon />
						</IconButton>
						<Menu
							id="menu-appbar"
							anchorEl={anchorElNav}
							anchorOrigin={{
								vertical: "bottom",
								horizontal: "left",
							}}
							keepMounted
							transformOrigin={{
								vertical: "top",
								horizontal: "left",
							}}
							open={Boolean(anchorElNav)}
							onClose={handleCloseNavMenu}
							sx={{
								display: { xs: "block", md: "none" },
							}}
						>
							{pages.map((page) => (
								<Link to={page.href} key={page.name}>
									<MenuItem
										key={page.name}
										onClick={handleCloseNavMenu}
									>
										<Typography
											textAlign="center"
											color={"black"}
										>
											{page.name}
										</Typography>
									</MenuItem>
								</Link>
							))}
						</Menu>
					</Box>
					<img
						src={logo}
						width="20"
						height="20"
						alt="AWS Certified Solutions Architect Associate"
						className={styles.mobileLogo}
					/>
					<Typography
						variant="h5"
						noWrap
						component="a"
						href="/"
						sx={{
							mr: 2,
							display: { xs: "flex", md: "none" },
							flexGrow: 1,
							fontFamily: "monospace",
							fontWeight: 700,
							letterSpacing: ".2rem",
							textDecoration: "none",
						}}
					>
						Prajwal Anjanappa
					</Typography>

					<Box
						sx={{
							display: { xs: "none", md: "flex", mr: "130px" },
						}}
					>
						{pages.map((page) => (
							<Link to={page.href} key={page.name}>
								<Button
									key={page.name}
									onClick={handleCloseNavMenu}
									sx={{
										my: 2,
										color: page.href === `/${active}`? 'grey': "black",
										display: "block",
									}}
								>
									{page.name}
								</Button>
							</Link>
						))}
					</Box>
				</Toolbar>
			</Container>
		</AppBar>
	);
}
export default ResponsiveAppBar;
