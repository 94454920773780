export const techLogos = {
	js: "https://cdn.jsdelivr.net/npm/programming-languages-logos/src/javascript/javascript.png",
	java: "https://cdn.jsdelivr.net/npm/programming-languages-logos@0.0.3/src/java/java.png",
	react: "https://img.icons8.com/color/48/react-native.png",
	node: "https://img.icons8.com/color/48/nodejs.png",
	mongodb: "https://img.icons8.com/color/48/mongodb.png",
	sql: "https://img.icons8.com/color/48/sql.png",
	android: "https://img.icons8.com/color/48/android-studio--v3.png",
	gcp: "https://img.icons8.com/color/48/google-cloud.png",
	firebase: "https://img.icons8.com/color/48/google-firebase-console.png",
	firestore: "https://img.icons8.com/color/48/cloud-firestore.png",
	psql: "https://img.icons8.com/color/48/postgreesql.png",
	redis: "https://img.icons8.com/color/48/redis.png",
	aws: "https://img.icons8.com/color/48/amazon-web-services.png",
	firebasedb: "https://img.icons8.com/color/48/realtime-database.png",
	nextjs: "https://cdn.cdnlogo.com/logos/n/80/next-js.svg",
	nestjs: "https://img.icons8.com/color/48/nestjs.png",
	docker: "https://img.icons8.com/color/48/docker.png",
	kubernetes: "https://img.icons8.com/color/48/kubernetes.png",
	git: "https://img.icons8.com/color/48/git.png",
	gitlab: "https://img.icons8.com/color/48/gitlab.png",
	bitbucket:
		"https://img.icons8.com/external-tal-revivo-shadow-tal-revivo/24/external-bitbucket-is-a-web-based-version-control-repository-hosting-service-logo-shadow-tal-revivo.png",
	github: `data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADIAAAAyCAYAAAAeP4ixAAAACXBIWXMAAAsTAAALEwEAmpwYAAADtklEQVR4nO2ZSWgVQRCGP2OCS3CJYoy7uCtiDi6o8aAIikvQi4oGvCiiRo2E6FXJQdxQg4LgUTx4cyPuHhVRD0bcsyDu4IJrTNTnSEMNPOfNm1czb2YSJD8UDNNT1fV3V1dX90AH/l8UAEuBfUAt8Bj4CLSKmOdH0ma+WQL0pp2gC1AGXAJ+A5ZPMToXgFViK3Z0AyqBVwGcTycvga1A17hILAAaQiTglHpgfpQEzNTXREjAKcdl5kNFf+BOjCQskVtAYVgkhst0W20kT8WHrNBP0qjVxtIAFAUl0bWNwsnyCLNAKfpoO3DecsjhICnWy+B2CbspwA7gWRbOmd1+G1As1cGBDN/P05LoptgnBruEoSH0A7gKVACzgNFAvsgYebcROAN8BTYDnR22ihWLXxVilYpRTLf75mlHy+PbAYr+zUB5oouy7Ah9o0pCkaL/F5lmpUwZ1+MiJFKi9GGll5FLSiPLIyRSrvThfDoDBT5K8eoIiRxT+vAL6OlmYKnSwGdZkFFhPPBT6Uupm4H9SmWT56PGSaUve92Ua5XK02Igskzpy1k35afKuMyNgchYJRFT0KbgvULRfBMHhiiJvHNTblUomm86xUBkoiMKPor8cfjT4qZsZ4rZUu+MAPoAA+XZljiIJCNXtoYC6dtUFYOSBjYFn6TxJnAXaJRQeiPPtqwgehz2iIrvScvAzFIKnkjjNUmxWyRPm4p1khw37VGJGjnS11BggmTKRVI575a7MPsIkIKL0rhLqsuDwCngOlAns/FBpnN1xLPRIqPdBDwAbgPngCNyFtrvVaZUKzOFkW8yU2FjncuC9pKdbkbm+jBgpBlYE1KomZJ8j08SRua4GeuuTMFOuSFryXnS0yBfBqMxQL8tXucie504xZxT1soGlM7wW+AEsEFGaiTQK8l2XznHmOvQKikvvgYgYImYkiotSj1SXomcwd8qw65KbihtFMq75iyct5JkYaa015RGsU7apwJfMpAwpNOhJAQy9eKLJyo8DJhcbpcQFyU07J84z4ErwOJMHQDrsyRSrr3duBckLn0gx6MPK4Pc9VOBzwQSLkYSIe4fGwKQSADT/XZ0JI2xT3KxNlgTpx4YFYBITZCO8qTu8tNRZ5/2/di+7PMC8B/09BnLfqG1+yCMP8DDgIdtSOS+nBhDQQ+pNOMmciWKf/F5UmInYiCSAA5FfdExWc4HURGpA2YQE3IlBTc4fvj7xeskfWNrU0zXTSnIkbLldFL54gelorswyz2pAx0gIvwFLXDNiM6zHVAAAAAASUVORK5CYII=`,
	travisci: "https://img.icons8.com/color/48/travis-ci.png",
	redux: "https://img.icons8.com/color/48/redux.png",
	mysql: "https://img.icons8.com/color/48/my-sql.png",
	html: "https://cdn.jsdelivr.net/npm/programming-languages-logos@0.0.3/src/html/html.png",
	css: "https://img.icons8.com/color/48/css3.png",
	wordpress: "https://img.icons8.com/color/48/wordpress.png",
  typescript:"https://cdn.jsdelivr.net/npm/programming-languages-logos/src/typescript/typescript.png",
	graphql:"https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSJvEkGcU44P32hrINDqyPyCz4V0kKIGTIRAvvfBEMWZQ&s"
};
