import { createTheme } from "@mui/material/styles";
import { red } from "@mui/material/colors";

const fontWeight = {
	Regular: 400,
	Medium: 500,
	SemiBold: 600,
	Bold: 700
}

const colors = {
	Dark: "#172B4D",
	Mid: "#505F79",
	Light: "#6B778C",
	Disabled: "#B3BAC4"
}

export const theme = createTheme({
	spacing: 4,
	palette: {
		primary: {
			main: "#3742fa",
			dark: "#4433B8",
			purple: '#4E3BC9',
			lightgray: '#F7F3F2',
      background: "#F1F2F4",
		},
		secondary: {
			main: "#499FDE",
			green: "#57B97F",
			blue: "#499FDE",
			candyPink: "#E46466",
			palePink: "#F9DBDA",
		},
		grey: {
			grey1: "#1D1934",
			grey2: "#1D1934",
			grey3: "#1D1934",
			grey4: "#1D1934",
			grey5: "#1D1934",
		},
		common: {
			black: "#000000",
			white: "#FFFFFF",
		},
		text: {
			dark: colors.Dark,
			mid: colors.Mid,
			light: colors.Light,
			disabled: colors.Disabled,
		},
		error: {
			main: "#FD573A",
		},
		warning: {
			main: "#FDAA29",
		},
		success: {
			main: "#3DB280",
		},
		background: {
			bg1: "#FAFBFC",
			bg2: "#F5F6F8",
			bg3: "#F4F5F7",
			bg4: "#F1F2F4",
			bg5: "#B3BAC4",
			bgWhite: "#FFFFFF",
		},
		link: {
			main: "#4E3BC9",
			pressed: "#4433B8",
		},
		icon: {
			main: "#45556E",
			light: "#637085",
			disabled: "#B3BAC4",
		},
		border: {
			main: "#768294",
			light: "#DCDFE4",
			disabled: "#F1F2F4",
		},
		brand: {
			main: "#4e3bc9",
			light: "#f5f4fc",
			disabled: "#e7e5f8",
		},
		accent: {
			accent1: "#f3f0ff",
			accent2: "#dfd9fc",
			accent3: "#9f92ed",
			accent4: "#6e60c4",
		},
		chart: {
			chart1: "#a6adba",
			chart2: "#fd8f77",
			chart3: "#c0b8f0",
			chart4: "#519dfc",
			chart5: "#7ee2f1",
			chart6: "#7ef1c1",
			chart7: "#fec32d",
			chart8: "#7b8799",
		},
		input: {
			disabled: "#f7f8f9",
			hover: "f7f8f9",
			focus: "#ffffff",
		},
		black: '#000000',
		white: '#FFFFFF',
		error: red
	},
	components: {
		MuiButton: {
			styleOverrides: {
				root: ({ ownerState }) => ({
					textTransform: 'none',
					fontWeight: fontWeight.SemiBold,
					borderRadius: '8px',
					...(ownerState.size === 'small' && {
						fontSize: '14px',
						lineHeight: '20px',
						padding: '6px 10px',
						borderRadius: '6px'
					}),
					...(ownerState.size === 'medium' && {
						fontSize: '16px',
						lineHeight: '20px',
						padding: '10px 10px'
					}),
					...(ownerState.size === 'large' && {
						fontSize: '18px',
						lineHeight: '24px',
						padding: '12px 10px'
					})
				})
			}
		},
	/* 	MuiInputBase: {
			styleOverrides: {
				root: ({ ownerState }) => ({
					color: colors.Dark,
					...(ownerState.size === 'small' && {
						"& > input": {
							padding: '7px 14px',
							height: '18px'
						},
						height: '32px',
						fontSize: '14px',
						lineHeight: '18px'
					}),
					...(ownerState.size === 'medium' && {
						"& > input": {
							padding: '14px 16px',
							height: '20px'
						},
						height: '48px',
						lineHeight: '20px'
					})
				})
			}
		},
		MuiOutlinedInput: {
			styleOverrides: {
				root: {
					"&.Mui-focused": {
						"& .MuiOutlinedInput-notchedOutline": {
							borderWidth: `1px`,
						},
					},
					"& .MuiOutlinedInput-notchedOutline": {
							borderColor: '#dddfe3'
						},
				},
			}
		} */

	},
	shadows: [
		"none",
		"0px 1px 1px rgba(9, 30, 66, 0.25), 0px 0px 1px rgba(9, 30, 66, 0.31)",
		"0px 1px 1px rgba(9, 30, 66, 0.25), 0px 0px 1px rgba(9, 30, 66, 0.31)",
		"0px 8px 12px rgba(9, 30, 66, 0.15), 0px 0px 1px rgba(9, 30, 66, 0.31)",
		"0px 0px 8px rgba(9, 30, 66, 0.16), 0px 0px 1px rgba(9, 30, 66, 0.12)",
		...Array(20).fill('none')
	],
	typography: {
		fontFamily: ['"Outfit"', "sans-serif"].join(","),
		fontWeightSemiBold: fontWeight.SemiBold,
		h1: {
			fontSize: 24,
			fontWeight: fontWeight.Regular,
			lineHeight: "28.8px",
			color: colors.Dark
		},
		h2: {
			fontSize: 20,
			fontWeight: fontWeight.Regular,
			lineHeight: "24px",
			color: colors.Dark
		},
		h3: {
			fontSize: 18,
			fontWeight: fontWeight.Regular,
			lineHeight: "21.6px",
			color: colors.Dark
		},
		h4: {
			fontSize: 16,
			fontWeight: fontWeight.Regular,
			lineHeight: "19.2px",
			color: colors.Dark
		},
		h5: {
			fontSize: 14,
			fontWeight: fontWeight.Regular,
			lineHeight: "16.8px",
			color: colors.Dark
		},
		h6: {
			fontSize: 12,
			fontWeight: fontWeight.Regular,
			lineHeight: "14.4px",
			color: colors.Dark
		},
		subtitle1: {
			fontSize: 16,
			fontWeight: fontWeight.Regular,
			lineHeight: "20px",
			color: colors.Light
		},
		subtitle2: {
			fontSize: 14,
			fontWeight: fontWeight.Regular,
			lineHeight: "16.8px",
			color: colors.Light
		},
		body1: {
			fontSize: 16,
			fontWeight: fontWeight.Regular,
			lineHeight: "19.2px",
			color: colors.Mid
		},
		body2: {
			fontSize: 14,
			fontWeight: fontWeight.Regular,
			lineHeight: "16.8px",
			color: colors.Mid
		},
		button: {},
		caption: {
			fontSize: 12,
			fontWeight: fontWeight.Regular,
			lineHeight: "14.4px",
			color: colors.Light
		},
		overline: {}
	},
});
