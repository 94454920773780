import React, { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Helmet } from "react-helmet";
import styled from "styled-components";

import NavBar from "../components/common/navBar";
import Footer from "../components/common/footer";
import Logo from "../components/common/logo";
import Notfound from "./404";

import user from "../data/user";
import ReactGA from "react-ga4";

import "./styles/readArticle.css";

import { Stack, Grid, Button, Divider } from "@mui/material";
import Typography from "../components/common/Typography";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import { Link } from "react-router-dom";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import AllProjects from "../components/projects/allProjects";

const ReadProject = () => {
	const navigate = useNavigate();
	let { slug } = useParams();

	const project = user.projects.find((proj) => proj.slug === slug);
	let ArticleStyle = styled.div``;

	useEffect(() => {
		window.scrollTo(0, 0);
		ReactGA.send({
			hitType: "pageview",
			page: "/read-project",
			title: "read-project",
		});
	}, [project]);

	if (!project) {
		return <Notfound />;
	}

	const breadcrumbs = [
		<Link
			underline="hover"
			key="1"
			color="inherit"
			to="/"
			sx={{
				color: "primary.main",
				cursor: "pointer",
			}}
		>
			Overview
		</Link>,
		<Link
			underline="hover"
			key="2"
			color="inherit"
			to="/projects"
			sx={{
				color: "primary.main",
				cursor: "pointer",
			}}
		>
			Projects
		</Link>,
		<Typography key="3" color="text.primary">
			{project.title}
		</Typography>,
	];

	return (
		<React.Fragment>
			<Helmet>
				<title>{`${project.title} | ${project.title}`}</title>
				<meta name="description" content={project.description} />
				<meta name="keywords" content={project?.keywords?.join(", ")} />
			</Helmet>

			<div className="page-content">
				<NavBar active="projects" />
				<div className="content-wrapper">
					<Stack
						sx={{
							marginTop: "150px",
						}}
					>
						<Breadcrumbs
							separator={<NavigateNextIcon fontSize="small" />}
							aria-label="breadcrumb"
						>
							{breadcrumbs}
						</Breadcrumbs>
					</Stack>
					<div className="read-article-container">
						<div className="read-article-wrapper">
							<div className="title read-article-title">
								{project.title}
							</div>

							<div className="read-article-body">
								<Typography variant="h2" fontWeight={700}>
									Technologies used
								</Typography>
								<Grid
									container
									md={12}
									columnSpacing={"8px"}
									mt={4}
									mb={8}
								>
									{project.logos.map((logo) => (
										<Grid item>
											<div className="project-logo">
												<img src={logo} alt="logo" />
											</div>
										</Grid>
									))}
								</Grid>
								<Typography variant="h2" fontWeight={700}>
									Scope of work
								</Typography>
								<Typography variant="body1" mt={4} mb={8}>
									{project?.scope}
								</Typography>
								<Stack mb={4}>
									<Typography
										variant="body1"
										sx={{
											lineHeight: 2,
											textAlign: "justify",
										}}
									>
										{project.description}
									</Typography>
								</Stack>
								{project?.contributions && (
									<>
										<Typography
											variant="h2"
											fontWeight={700}
										>
											My contributions
										</Typography>

										<Typography
											variant="body1"
                      sx={{
                        lineHeight:2
                      }}
											dangerouslySetInnerHTML={{
												__html: project.contributions,
											}}
										></Typography>
									</>
								)}
								{project.link && (
									<Link to={project.link} target="_blank">
										<Button variant="outlined">
											Project Link
										</Button>
									</Link>
								)}
								<Divider
									sx={{
										width: "100%",
										color: "primary.main",
										mt: 4,
										mb: 4,
										display: "flex",
										height: "4px",
									}}
								/>
								<Typography variant="h2" fontWeight={700}>
									See more Projects
								</Typography>{" "}
								<AllProjects short={true} clamp={3} />
							</div>
						</div>
					</div>
					<div className="page-footer">
						<Footer />
					</div>
				</div>
			</div>
		</React.Fragment>
	);
};

export default ReadProject;
