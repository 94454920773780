import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";

import NavBar from "../components/common/navBar";
import Footer from "../components/common/footer";
import Logo from "../components/common/logo";
import Socials from "../components/about/socials";
import ReactGA from "react-ga4";
import emailjs from "@emailjs/browser";
import { TextField, Stack, Button, Snackbar } from "@mui/material";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";

import INFO from "../data/user";
import SEO from "../data/seo";

import "./styles/contact.css";

const Contact = () => {
	const [name, setName] = useState("");
	const [replyTo, setReplyTo] = useState("");
	const [subject, setSubject] = useState("");
	const [message, setMessage] = useState("");
	const [phone, setPhone] = useState("");
	const [open, setOpen] = React.useState(false);

	const handleClose = (event, reason) => {
		if (reason === "clickaway") {
			return;
		}

		setOpen(false);
	};

	const action = (
		<React.Fragment>
			<IconButton
				size="small"
				aria-label="close"
				color="inherit"
				onClick={handleClose}
			>
				<CloseIcon fontSize="small" />
			</IconButton>
		</React.Fragment>
	);

	useEffect(() => {
		window.scrollTo(0, 0);
		ReactGA.send({
			hitType: "pageview",
			page: "/contact",
			title: "contact",
		});
	}, []);

	const currentSEO = SEO.find((item) => item.page === "contact");

	const handleSubmit = async () => {
		await emailjs.send("service_2ecdoto", "template_kjs9qbp", {
			subject: subject,
			to_name: "Prajwal",
			from_name: name,
			message: message,
			phone: phone,
			reply_to: replyTo,
		});
		setOpen(true);
		setName("");
		setMessage("");
		setPhone("");
		setReplyTo("");
		setSubject("");
	};

	return (
		<React.Fragment>
			<Helmet>
				<title>{`Contact | ${INFO.main.title}`}</title>
				<meta name="description" content={currentSEO.description} />
				<meta
					name="keywords"
					content={currentSEO.keywords.join(", ")}
				/>
			</Helmet>

			<div className="page-content">
				<NavBar active="contact" />
				<div className="content-wrapper">
					<div className="contact-container">
						<div className="title contact-title">
							Let's Connect and Make Ideas Happen
						</div>

						<img
							src={"/contact.webp"}
							width={"100%"}
							height={"300px"}
							style={{
								objectFit: "cover",
								marginTop: "50px",
							}}
							alt={"Contact"}
						/>

						<Stack
							m={3}
							mt={5}
							mb={5}
							p={5}
							pb={10}
							border={"1px solid #6B778C"}
							borderRadius={"8px"}
							spacing={3}
							id="contact-me"
						>
							<h3>Get in Touch</h3>
							<TextField
								variant="outlined"
								label="Name"
								value={name}
								onChange={(e) => setName(e.target.value)}
							/>
							<TextField
								variant="outlined"
								label="Subject"
								value={subject}
								onChange={(e) => setSubject(e.target.value)}
							/>
							<TextField
								variant="outlined"
								label="E-mail"
								value={replyTo}
								onChange={(e) => setReplyTo(e.target.value)}
							/>
							<TextField
								variant="outlined"
								label="Phone"
								value={phone}
								onChange={(e) => setPhone(e.target.value)}
							/>
							<TextField
								variant="outlined"
								label="Message"
								multiline
								maxRows={4}
								minRows={4}
								value={message}
								onChange={(e) => setMessage(e.target.value)}
							/>
							<Button
								variant="contained"
								disabled={!(replyTo || phone)}
								onClick={() => {
									handleSubmit();
									ReactGA.send({
										hitType: "contact-sent",
										page: "/contact",
										title: "contact",
									});
								}}
							>
								Send Message
							</Button>
						</Stack>

						<div className="subtitle contact-subtitle">
							Thank you for your interest in getting in touch. I
							would be delighted to connect with you and discuss
							how we can collaborate, bring ideas to life, or
							simply engage in meaningful conversations. Please
							use the provided contact information &nbsp;{" "}
							<a href={`mailto:${INFO.main.email}`}>
								{INFO.main.email}
							</a>
							<h3>
								I'm available for a variety of opportunities,
								including:
							</h3>
							<ul>
								<li>
									<b>Project Collaborations:</b> If you have a
									project idea or a business opportunity that
									you believe would benefit from my expertise,
									I would love to hear from you. Let's discuss
									your vision, goals, and how we can work
									together to make it a reality.
								</li>
								<li>
									<b>Consulting Engagements:</b> Are you
									seeking advice or guidance in the realms of
									software development, innovation, or
									technology strategy? I offer consulting
									services tailored to your specific needs.
									Let's explore ways to leverage technology to
									drive your business forward.
								</li>
								<li>
									<b>Speaking Engagements:</b> If you're
									organizing an event or conference and are
									looking for an engaging speaker to share
									insights on topics such as full stack
									development, innovation, or emerging
									technologies, I would be thrilled to
									contribute. Let's discuss the details and
									make your event a success.
								</li>
								<li>
									<b>General Inquiries:</b> Have a question,
									feedback, or simply want to connect? Don't
									hesitate to drop me a message. I'm always
									open to engaging in discussions, exchanging
									ideas, and connecting with like-minded
									individuals.
								</li>
							</ul>
							<p>
								I value your time and assure you that I will
								respond promptly to any inquiries or messages
								received. Let's connect, explore possibilities,
								and turn ideas into reality.
							</p>
							<p>
								Thank you for considering reaching out. I look
								forward to hearing from you and the potential of
								working together on exciting endeavors.
							</p>
						</div>
					</div>

					<div className="socials-container">
						<div className="contact-socials">
							<Socials />
						</div>
					</div>

					<div className="page-footer">
						<Footer />
					</div>
				</div>
			</div>
			<Snackbar
				open={open}
				autoHideDuration={6000}
				onClose={handleClose}
				message="Email Sent"
				action={action}
			/>
		</React.Fragment>
	);
};

export default Contact;
