import React, { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Helmet } from "react-helmet";
import styled from "styled-components";

import NavBar from "../components/common/navBar";
import Footer from "../components/common/footer";
import Logo from "../components/common/logo";

import INFO from "../data/user";
import myArticles from "../data/articles";
import ReactGA from "react-ga4";

import { Stack, Grid, Button, Divider } from "@mui/material";
import Typography from "../components/common/Typography";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import { Link } from "react-router-dom";
import "./styles/readArticle.css";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";

let ArticleStyle = styled.div``;

const ReadArticle = () => {
	const navigate = useNavigate();
	let { slug } = useParams();

	const article = myArticles[slug - 1];

	useEffect(() => {
		window.scrollTo(0, 0);
		ReactGA.send({
			hitType: "pageview",
			page: "/read-article",
			title: "read-article",
		});
	}, [article]);

	ArticleStyle = styled.div`
		${article().style}
	`;

	const breadcrumbs = [
		<Link
			underline="hover"
			key="1"
			color="inherit"
			to="/"
			sx={{
				color: "primary.main",
				cursor: "pointer",
			}}
		>
			Overview
		</Link>,
		<Link
			underline="hover"
			key="2"
			color="inherit"
			to="/articles"
			sx={{
				color: "primary.main",
				cursor: "pointer",
			}}
		>
			Blog
		</Link>,
		<Typography key="3" color="text.primary">
			{article().title}
		</Typography>,
	];

	return (
		<React.Fragment>
			<Helmet>
				<title>{`${article().title} | ${INFO.main.title}`}</title>
				<meta name="description" content={article().description} />
				<meta name="keywords" content={article().keywords.join(", ")} />
			</Helmet>

			<div className="page-content">
				<NavBar active="articles" />

				<div className="content-wrapper">
					<Stack
						sx={{
							marginTop: "150px",
						}}
					>
						<Breadcrumbs
							separator={<NavigateNextIcon fontSize="small" />}
							aria-label="breadcrumb"
						>
							{breadcrumbs}
						</Breadcrumbs>
					</Stack>
					<div className="read-article-container">
						<div className="read-article-wrapper">
							<div className="read-article-date-container">
								<div className="read-article-date">
									{article().date}
								</div>
							</div>

							<div className="title read-article-title">
								{article().title}
							</div>

							<div className="read-article-body">
								<ArticleStyle>{article().body}</ArticleStyle>
							</div>
						</div>
					</div>
					<div className="page-footer">
						<Footer />
					</div>
				</div>
			</div>
		</React.Fragment>
	);
};

export default ReadArticle;
