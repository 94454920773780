import { openSourceContribution } from "./articles/opensource";
import { cleanCode } from "./articles/cleancode";
import { learning } from "./articles/learning";
import { stressManagement } from "./articles/stressManagement";

const myArticles = [
	stressManagement,
	learning,
	openSourceContribution,
	cleanCode,
];

export default myArticles;
