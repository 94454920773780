import { Typography as MUITypography } from '@mui/material';
import { styled } from '@mui/system';

const Typography = styled(MUITypography, {
	shouldForwardProp: (prop) => prop !== 'clamp'
})(({ theme, clamp }) => ({
	...(clamp && {
		display: '-webkit-box',
		WebkitLineClamp: clamp.toString(),
		WebkitBoxOrient: 'vertical',
		overflow: 'hidden'
	})
}));

export default Typography;