import { techLogos } from "./logos";

const INFO = {
	main: {
		title: "Prajwal | Fullstack dev | Solutions Architect",
		name: "Prajwal Anjanappa.",
		email: "praju.anjanappa@gmail.com",
		logo: "../logo.png",
	},

	socials: {
		twitter: "https://twitter.com/praju_anjanappa",
		github: "https://github.com/prajwal183",
		linkedin: "https://www.linkedin.com/in/prajwal183",
		instagram: "https://instagram.com/thatindiandev",
		gitlab: "https://gitlab.com/",
		medium: "https://medium.com/@praju.anjanappa",
	},

	homepage: {
		title: "Senior Fullstack Engineer | Project Manager | AWS certified Solutions Architect",
		description: `
		 Hello, I'm Prajwal. \n
		 I'm a senior full stack developer and AWS certified solutions architect
		 with over 6+ years of experience building and scaling web and mobile applications.
		 I have lead teams of developers in building highly scalable solutions on AWS and other 
		 cloud platforms.My Expertise lies in planning,architecting, executing, and closing projects, 
		 ensuring that they are completed on time, with highly scalable design principles, and to the required quality 
		 standards.
		`,
	},

	about: {
		title: " I'm Prajwal Anjanappa, a seasoned senior full stack developer and visionary dedicated to pushing the boundaries of technology and creating impactful digital experiences. Allow me to share a bit about myself and my journey in the world of development.",
	},

	articles: {
		title: "Insights, Ideas, and Inspiration for the Curious Mind",
		description:
			"A treasure trove of insights, ideas, and inspiration for the curious minds seeking to delve deeper into the world of technology, development, and innovation. Here, I share my thoughts, experiences, and discoveries through a collection of carefully crafted articles. Join me on a journey of learning and exploration as we uncover valuable insights together.",
	},

	projects: [
		{
			title: "Media Ant India's first e-commerce platform for media buying",
			description: `As the Lead developer behind The Media Ant website, I architected and implemented a 
			comprehensive online platform aimed at transforming the advertising industry by simplifying 
			the media buying and planning process. Recognizing the need for a more streamlined approach, 
			we focused on creating a user-friendly interface that enables businesses of all sizes to efficiently
			discover, compare, and select the best advertising options available across multiple channels 
			including digital, television, radio, outdoor and more.
			The core of the website leverages advanced data analytics to provide real-time insights into 
			campaign performance, helping advertisers make informed decisions to maximize
			their ROI. I integrated a variety of technologies including Next.js for its SSR capabilities and dynamic user interactions,
			Node.js with Express for robust backend services and Nest.js for scalable microservices architecture.
			MongoDB was chosen for its scalability in handling large datasets, crucial for the extensive media database.
			Security and performance were top priorities. I implemented a huge number of features including 
			features for secure authentication
			and used CDN and caching strategies to enhance site speed and user experience. Each feature was 
			meticulously crafted to ensure it not only meets the functional requirements but also exceeds user 
			expectations in ease of use and effectiveness.`,
			logos: [
				techLogos.nestjs,
				techLogos.typescript,
				techLogos.react,
				techLogos.nextjs,
				techLogos.node,
				techLogos.mongodb,
				techLogos.wordpress,
				techLogos.docker,
				techLogos.redis,
				techLogos.psql,
				techLogos.redux,
				techLogos.gcp,
				techLogos.aws,
				techLogos.bitbucket,
			],
			linkText: "See more",
			link: "https://themediaant.com",
			routeLink: "/projects/media-ant",
			slug: "media-ant",
			scope: "Project Manager, Lead Fullstack developer",
			contributions: `
			<ul>
    <li><b>Framework Optimization:</b> Led the strategic shift from React.js to Next.js to leverage superior server-side rendering, significantly enhancing performance and scalability.</li>
    <li><b>Architecture Overhaul:</b> Migrated from Portal-react to portal-next, implemented a cutting-edge design, and restructured the architecture to bolster scalability.</li>
    <li><b>State Management Upgrade:</b> Revolutionized state management by adopting Redux Toolkit over traditional Redux, implemented lazy loading to significantly improve our Lighthouse score.</li>
    <li><b>Performance Enhancement:</b> Achieved a substantially improved Lighthouse score compared to the original React.js project, reflecting advanced frontend optimizations.</li>
    <li><b>Client-Facing Dashboard Development:</b> Engineered a real-time analytics and campaign dashboard to monitor campaign performance, planning, brief, schedule and execution dynamically.</li>
    <li><b>Financial Transaction Integration:</b> Seamlessly integrated Razorpay as the payment gateway to streamline transaction processes.</li>
    <li><b>Data Structure Refinement:</b> Expertly restructured the data model to support enhanced scalability and more efficient data operations.</li>
    <li><b>Advanced Cart System Engineering:</b> Developed the sophisticated core model of the shopping cart, managing both standard and advanced pricing units with precision.</li>
    <li><b>Robust Authentication System:</b> Constructed a secure authentication system utilizing JWT, sessions, and modern authentication protocols.</li>
    <li><b>Caching and Content Delivery Optimization:</b> Architected the Redis cache and transitioned to AWS CloudFront to maximize content delivery speed and performance.</li>
    <li><b>Bundle Size Optimization:</b> Drastically optimized the bundle size, further enhancing the site's Lighthouse score and user experience.</li>
    <li><b>Ad Revenue Calculator Innovation:</b> Crafted advanced ad revenue calculators to provide users with clear ROI insights on their ad spend.</li>
    <li><b>Database Hosting Migration:</b> Transitioned MongoDB hosting from ScaleGrid to MongoDB Atlas to utilize its comprehensive feature set and achieve superior database control.</li>
    <li><b>Disaster Recovery Setup:</b> Implemented a robust Point-In-Time Recovery (PITR) strategy for effective disaster management.</li>
    <li><b>Cybersecurity Measures:</b> Fortified the website against persistent DDOS attacks through best-practice security protocols.</li>
    <li><b>AI-Driven Proof Processing:</b> Utilized AI technology to automate and process proof of execution for campaigns.</li>
    <li><b>Asynchronous Task Handling:</b> Integrated Amazon SQS for efficient asynchronous processing of tasks.</li>
    <li><b>Job Processing Framework:</b> Implemented BullMQ for handling complex job processing requirements.</li>
    <li><b>Financial Operations Core Model:</b> Tightly integrated with Zoho to manage and streamline financial operations.</li>
    <li><b>GraphQL and Postgres Implementation:</b> Employed GraphQL and Postgres for a more dynamic and responsive analytics dashboard.</li>
    <li><b>TypeScript Transition:</b> Upgraded existing JavaScript projects to TypeScript to enhance development reliability and maintainability.</li>
    <li><b>NestJS Service Implementation:</b> Configured NestJS service to manage campaigns and led production efforts.</li>
    <li><b>Backend Testing Architecture:</b> Designed a robust backend testing framework using Jest for comprehensive coverage and reliability.</li>
    <li><b>Frontend Component Management:</b> Implemented Storybook to maintain high control over UI component development and ensure productivity.</li>
    <li><b>Task Management System Development:</b> Constructed the core model of task management, essential for daily operational workflows at The Media Ant.</li>
    <li><b>Revenue Monitoring Dashboard:</b> Created a revenue dashboard to track daily company revenue metrics accurately.</li>
    <li><b>Elasticsearch Implementation:</b> Deployed Elasticsearch to enhance advanced search capabilities across the platform.</li>
    <li><b>Client Collaboration and Product Development:</b> Managed client requests and feature implementations in close collaboration with the product team, converting PRDs into functional products and incorporating client feedback effectively.</li>
    <li><b>UI Framework Modernization:</b> Spearheaded the conversion of an old Angular dashboard to a modern React framework, enhancing UI responsiveness and maintainability.</li>
		<li><b>CI/CD Pipeline Construction:</b> Built a CI/CD pipeline to automate builds, testing, and deployments, enhancing development operations and ensuring high-quality production releases.</li>
</ul>

			`,
		},
		{
			title: "Media Ant Saas India's first enterprise solution to manage and execute campaigns",
			description: `The Media Ant's internal dashboard, initially developed for in-house use, evolved into a robust SaaS product due to its comprehensive suite of tools for managing advertising campaigns. This platform streamlines campaign management, media planning, and analytics, and automates proof of execution with AI technology. Key features include handling creatives, managing extensive media inventory, and scheduling campaigns efficiently. Integration with Razorpay facilitates secure payment processing, enhancing transaction convenience. The dashboard also includes advanced modules for automated planning and proof of execution collection, ensuring operational efficiency. Tight integration with Zoho enhances CRM capabilities, while Role-Based Access Control (RBAC) ensures that sensitive campaign data and functionalities are securely managed, making it a powerful tool for advertisers seeking precision and scalability in their marketing efforts.`,
			logos: [
				techLogos.nestjs,
				techLogos.typescript,
				techLogos.react,
				techLogos.graphql,
				techLogos.node,
				techLogos.mongodb,
				techLogos.psql,
				techLogos.redux,
				techLogos.aws,
				techLogos.bitbucket,
			],
			linkText: "See more",
			link: "https://themediaant.com",
			routeLink: "/projects/media-ant-admin",
			slug: "media-ant-admin",
			scope: "Project Manager, Lead Fullstack developer",
			contributions:`
			<ul>
    <li><b>Architecture Overhaul:</b> Spearheaded in setup and architecting of the project.</li>
    <li><b>Financial Transaction Integration:</b> Seamlessly integrated Razorpay as the payment gateway to streamline transaction processes.</li>
    <li><b>Data Structure Refinement:</b> Expertly restructured the data model to support enhanced scalability and more efficient data operations.</li>
    <li><b>Advanced Cart System Engineering:</b> Developed the sophisticated core model of the shopping cart, managing both standard and advanced pricing units with precision.</li>
    <li><b>Robust Authentication System:</b> Constructed a secure authentication system utilizing JWT, sessions, and modern authentication protocols.</li>
    <li><b>Ad Revenue Calculator Innovation:</b> Crafted advanced ad revenue calculators to provide users with clear ROI insights on their ad spend.</li>
    <li><b>Database Hosting Migration:</b> Transitioned MongoDB hosting from ScaleGrid to MongoDB Atlas to utilize its comprehensive feature set and achieve superior database control.</li>
    <li><b>Disaster Recovery Setup:</b> Implemented a robust Point-In-Time Recovery (PITR) strategy for effective disaster management.</li>
    <li><b>Cybersecurity Measures:</b> Fortified the website against persistent DDOS attacks through best-practice security protocols.</li>
    <li><b>AI-Driven Proof Processing:</b> Utilized AI technology to automate and process proof of execution for campaigns.</li>
    <li><b>Asynchronous Task Handling:</b> Integrated Amazon SQS for efficient asynchronous processing of tasks.</li>
    <li><b>Job Processing Framework:</b> Implemented BullMQ for handling complex job processing requirements.</li>
    <li><b>Financial Operations Core Model:</b> Tightly integrated with Zoho to manage and streamline financial operations.</li>
    <li><b>GraphQL and Postgres Implementation:</b> Employed GraphQL and Postgres for a more dynamic and responsive analytics dashboard.</li>
    <li><b>TypeScript Transition:</b> Upgraded existing JavaScript projects to TypeScript to enhance development reliability and maintainability.</li>
    <li><b>NestJS Service Implementation:</b> Configured NestJS service to manage campaigns and led production efforts.</li>
    <li><b>Backend Testing Architecture:</b> Designed a robust backend testing framework using Jest for comprehensive coverage and reliability.</li>
    <li><b>Frontend Component Management:</b> Implemented Storybook to maintain high control over UI component development and ensure productivity.</li>
    <li><b>Task Management System Development:</b> Constructed the core model of task management, essential for daily operational workflows at The Media Ant.</li>
    <li><b>Revenue Monitoring Dashboard:</b> Created a revenue dashboard to track daily company revenue metrics accurately.</li>
    <li><b>Elasticsearch Implementation:</b> Deployed Elasticsearch to enhance advanced search capabilities across the platform.</li>
    <li><b>Client Collaboration and Product Development:</b> Managed client requests and feature implementations in close collaboration with the product team, converting PRDs into functional products and incorporating client feedback effectively.</li>
    <li><b>UI Framework Modernization:</b> Spearheaded the conversion of an old Angular dashboard to a modern React framework, enhancing UI responsiveness and maintainability.</li>
		<li><b>CI/CD Pipeline Construction:</b> Built a CI/CD pipeline to automate builds, testing, and deployments, enhancing development operations and ensuring high-quality production releases.</li>
</ul>
			`
		},
		{
			title: "Invoice Desk",
			description: `Invoice Desk is an advanced invoicing solution designed to integrate effortlessly into existing workflows, enabling users to optimize the functionality of their applications through our suite of pre-built integrations. Built from the ground up with an API-first approach, this platform supports seamless interactions between your financial systems, enhancing capabilities from purchase order management to final invoice processing. Users can access Invoice Desk as a Web App or utilize our comprehensive APIs and client-side SDKs to integrate directly into their current systems or to develop customized financial solutions. This flexibility ensures optimal alignment with diverse financial operations, empowering businesses to streamline accounts receivable processes, automate billing cycles, and ensure accurate financial reporting—all from one centralized platform`,
			logos: [
				techLogos.typescript,
				techLogos.nestjs,
				techLogos.nextjs,
				techLogos.node,
				techLogos.psql,
				techLogos.js,
				techLogos.html,
				techLogos.css,
				techLogos.redux,
				techLogos.git,
				techLogos.aws,
			],
			linkText: "See more",
			link: "https://www.invoicedesk.app/",
			routeLink: "/projects/invoicedesk",
			slug: "invoicedesk",
			scope: "Backend developer",
		},

		{
			title: "TRIPLE ESS",
			description: `This web and Android app transforms the corrugated box manufacturing workflow by automating the entire production lifecycle from reel handling in inventory to the finished box product. It leverages real-time data to maximize operational efficiency and enables precise tracking of reel consumption and wastage through intuitive dashboards accessible on the employer’s mobile device. The core USP of this product lies in its sophisticated data acquisition capabilities at every critical point of the production line, including flute lamination, die-cutting, and box design. By utilizing mobile devices for seamless data capture and integrating advanced analytics in the web app, we ensure optimal resource utilization and enhanced operational oversight. This solution not only streamlines inventory management of paper reels but also supports proactive maintenance scheduling, reducing downtime and boosting production throughput.`,
			logos: [
				techLogos.js,
				techLogos.node,
				techLogos.java,
				techLogos.html,
				techLogos.css,
				techLogos.react,
				techLogos.mongodb,
				techLogos.android,
				techLogos.firebase,
				techLogos.redux,
				techLogos.git,
				techLogos.gcp,
			],
			linkText: "See more",
			link: "https://themit.in/packaging-erp/",
			routeLink: "/projects/triple-ess",
			slug: "triple-ess",
			scope: "Fullstack developer",
		},
		{
			title: "CEMS-vendor management",
			description: `This application streamlines the entire HR management and payroll lifecycle for contract-based employees, significantly enhancing the operational efficiency of handling contractual workforces. Our objective is to foster complete transparency in payroll transactions among employers, contractors, and employees through robust audit trails and real-time data analytics. The platform offers extensive customization to integrate seamlessly with varied HRM protocols, including compliance tracking, benefit management, and employee performance metrics. This flexibility ensures that every organization, regardless of industry or size, can effectively adapt and enhance their contract employment frameworks. By automating complex HR tasks such as payroll processing, tax calculations, and labor contract compliance, the app enables businesses to focus on strategic growth while ensuring adherence to evolving HR regulations.`,
			logos: [
				techLogos.js,
				techLogos.java,
				techLogos.html,
				techLogos.css,
				techLogos.react,
				techLogos.node,
				techLogos.mongodb,
				techLogos.sql,
				techLogos.android,
				techLogos.firebase,
				techLogos.redux,
				techLogos.git,
				techLogos.gcp,
			],
			linkText: "See more",
			link: "https://themit.in/cems-for-principal-employer/",
			routeLink: "/projects/cems",
			scope: "Lead Fullstack developer",
			slug: "cems",
		},
		{
			title: "Trac",
			description: `
			Trac is a mobile application designed to leverage the native capabilities of the Geolocation API, providing comprehensive management solutions for on-field employees across various sectors such as sales, management, driving, and delivery. This app enables real-time tracking of employees' locations, allowing for efficient task assignment and monitoring of their travel speed. It’s an essential tool for optimizing field operations, enhancing productivity, and ensuring accountability. Managers can assign tasks dynamically, receive status updates, and analyze performance data to improve job efficiency. The analytics feature offers deep insights into each employee's effectiveness, travel patterns, and time management. Trac is invaluable for businesses looking to streamline their field operations and improve their overall service delivery and operational efficiency.`,
			logos: [
				techLogos.js,
				techLogos.java,
				techLogos.react,
				techLogos.android,
				techLogos.firebase,
				techLogos.redux,
				techLogos.git,
			],
			linkText: "See more",
			routeLink: "/projects/trac",
			slug: "trac",
			scope: "Fullstack developer",
		},
	],
};

export default INFO;
